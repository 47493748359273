import { useRef, useState } from "react";
import $ from 'jquery';
import { useGSAP } from "@gsap/react";
import { gsap, scrollRefresh } from "../gsapConfig";

export default function Service({ refresh, setRefresh }) {
    const serviceRef = useRef()
    const headingRef = useRef();
    const contentRef = useRef();
    const descriptionRef = useRef();
    const accordionRef = useRef();



    const [active, setActive] = useState(null);

    const toggle = (i) => {
        $(".accordian").find(".text").stop().slideUp()
        $(".accordian").find(".button span.vertical").removeClass("vertical-rotate")
        if ($(".accordian").eq(i).find(".text").css("display") == "none") {
            $(".accordian").eq(i).find(".text").stop().slideDown(400, "swing", () => {
                scrollRefresh()
            })
            $(".accordian").eq(i).find(".button span.vertical").addClass("vertical-rotate")
        }
    };

    useGSAP(() => {
        let mm2 = gsap.matchMedia();
        mm2.add("(min-width: 991px)", () => {

            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: serviceRef.current,
                    start: "top center",
                    end: '+=400',
                    toggleActions: "play reverse play reverse",
                    invalidateOnRefresh: true,
                    scrub: 1,
                }
            });

            tl2.fromTo(headingRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, 0);
            tl2.fromTo(contentRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
            tl2.fromTo(descriptionRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
            tl2.fromTo(accordionRef.current, { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");


        });

        // Optionally, add a fallback for screens smaller than 991px if needed
        mm2.add("(max-width: 990px)", () => {
            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: serviceRef.current,
                    start: "top center",
                    end: '+=400',
                    toggleActions: "play reverse play reverse",
                    invalidateOnRefresh: true,
                    scrub: 1,
                }
            });

            tl2.fromTo(headingRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, 0);
            tl2.fromTo(contentRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
            tl2.fromTo(descriptionRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
            tl2.fromTo(accordionRef.current, { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
        });

    })

    return (
        <>
            <div className="services" id="services" ref={serviceRef}>
                <svg className="services-lines" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 402" fill="none">
                    <g clip-path="url(#clip0_2033_166)">
                        <path style={{transform: "translateX(-0.8px)"}} d="M957.5 -278C957.5 -210.333 957.5 -61.1 957.5 -5.5C957.5 59.5 888 50.5 888 50.5C773.167 50.5 336.7 50.5 279.5 50.5C222.3 50.5 218 91.5 218 112V255C218.833 268.833 211.7 296.5 176.5 296.5C141.3 296.5 31.8333 296.5 -18.5 296.5" stroke="#C1001D" stroke-width="2" />
                        <path style={{transform: "translateX(0.5px)"}} d="M791.5 -218C791.5 -150.334 791.5 -1.10018 791.5 54.4998C791.5 110.1 750 110.5 750 110.5C635.167 110.5 188.2 110.5 131 110.5C73.7998 110.5 69.5 150.5 69.5 178.5V305C70.3333 318.833 63.1998 346.5 27.9998 346.5C-7.20018 346.5 -116.667 346.5 -167 346.5" stroke="#C1001D" stroke-width="2" />
                        <path d="M139 338C139 270.333 139 259.1 139 203.5C139 139.5 208.5 147.5 208.5 147.5C323.333 147.5 567.3 147.5 624.5 147.5C681.7 147.5 686 109 686 86.0002V-206.5C685.167 -220.333 692.3 -248 727.5 -248C762.7 -248 872.167 -248 922.5 -248" stroke="#C1001D" stroke-width="2" />
                        <path d="M138.5 204C138.5 271.667 138.5 282.9 138.5 338.5C138.5 402.5 69 394.5 69 394.5C-45.8333 394.5 -289.8 394.5 -347 394.5C-404.2 394.5 -411.833 435.5 -408.5 456V748.5C-407.667 762.333 -414.8 790 -450 790C-485.2 790 -594.667 790 -645 790" stroke="#C1001D" stroke-width="2" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2033_166">
                            <rect width="1440" height="402" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <div className="container">
                    <div className="content-wrap">
                        <h2 className="heading" ref={headingRef}>
                            Our <span>services</span>
                        </h2>
                        <p className="text" ref={contentRef}>
                            We tailor our services to meet the unique needs of each client, ensuring a personalized experience.
                        </p>
                        <p className="description" ref={descriptionRef}>
                            Additionally, we offer customizable features for every service, ensuring that you receive the exact support you need to succeed.
                        </p>
                    </div>
                    <div className="accordian-wrap" ref={accordionRef}>
                        {
                            ServicesData.map((link, i) => {
                                return (
                                    <div className="accordian" onClick={() => toggle(i)}>
                                        <h3 className="heading">
                                            {link.linkName}
                                        </h3>
                                        <div className={active === i ? "text show" : "text"}>
                                            {link.linkContent}
                                        </div>
                                        <div className="button">
                                            <span className={active === i ? "vertical vertical-rotate" : "vertical"}></span>
                                            <span className="horizontal"></span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

const businessStrategyContent = (
    <>
        The business strategy ensures the client’s full awareness not only of the time needed to implement the project and each of its milestones but also allows for budget planning. For this purpose, our Infinity team combines diverse competencies: legal, business, marketing, and financial.
    </>
)
const daoContent = (
    <>
        <p className="bold">
            For Web3 projects, we handle legal compliance for DAOs, including:
        </p>
        <ul>
            <li>Drafting DAO documents like constitutions.</li>
            <li>Reviewing DAO communications.</li>
            <li>Crafting legal agreements for wrapper entities.</li>
            <li>Providing legal opinions on DAO-VASP cooperation.</li>
            <li>Offering training and workshops.</li>
            <li>Creating template agreements for wrapper entities.</li>
            <li>Analyzing cooperation with DAOs on internet platforms.</li>
        </ul>
    </>
);
const digitalTransformationContent = (
    <>
        <p className="bold">
            We guide businesses through digital transformation, helping them leverage the latest technologies to streamline operations.
        </p>
        <p className="text-left margin-sm">
            Our Guidance:
        </p>
        <ul>
            <li>Strategic consulting for tailored strategies.</li>
            <li>Technology integration like cloud computing and AI.</li>
            <li>Process optimization for efficiency.</li>
            <li>
                Customer experience enhancement with user-centric design.
            </li>
            <li>
                Change management support for a smooth transition.
            </li>
            <li>
                Future-proof your business, stay competitive, and grow with us as your trusted digital transformation partner.
            </li>
        </ul>
    </>
)
const individualTaxStrategiesContent = (
    <>
        <p className="bold">
            We offer personalized tax strategies tailored to your financial situation. Our expert team develops plans to maximize tax efficiency while ensuring compliance with regulations.
        </p>
        <p className="text-left margin-sm">
            Our services include:
        </p>
        <ul>
            <li>
                Customized plans to minimize tax liability and optimize financial outcomes.
            </li>
            <li>
                Strategies to help you keep more of your money
            </li>
            <li>
                Guidance to ensure compliance and avoid penalties.
            </li>
        </ul>
    </>
)
const ipStrategyContent = (
    <>
        <p className="bold">
            Our IP Strategy service helps businesses protect and leverage their intellectual assets effectively
        </p>
        <p className="text-left margin-sm">
            Our services include:
        </p>
        <ul>
            <li>
                Guidance on patents, trademarks, copyrights, and trade secrets.
            </li>
            <li>
                Strategies for monetizing IP through licensing and partnerships.
            </li>
            <li>
                Assistance in enforcing IP rights and defending against infringement.
            </li>
            <li>
                Long-term strategies aligned with business goals and industry trends
            </li>
        </ul>

    </>
)
const kolInfluencerComplianceSupportContent = (
    <>
        <p className="bold">
            We ensure your influencer marketing campaigns comply with regulations and optimize their effectiveness.
        </p>
        <p className="text-left margin-sm">
            Our services include:
        </p>
        <ul>
            <li>
                Assessing compliance with relevant regulations.
            </li>
            <li>
                Offering tailored guidance for your specific campaigns and industry.
            </li>
            <li>
                Providing contracts and procedures to manage influencer collaborations.
            </li>
        </ul>
    </>
)
const licensingContent = (
    <>
        <p className="bold">
            Infinity specializes in licensing financial institutions and Fintech companies in the UK and the EU.
        </p>
        <p className="text-left margin-sm">
            Our structured approach includes:
        </p>
        <ul>
            <li>
                Understanding risks and opportunities.
            </li>
            <li>
                Deciding on jurisdiction and license type.
            </li>
            <li>
                Organizing required documents.
            </li>
            <li>
                Handling interactions with authorities.
            </li>
            <li>
                Guiding you through the licensing process and ensuring ongoing compliance.
            </li>
        </ul>
        <br />
        <br />
        <p class="text-left margin-sm">Licenses for regulated activities:</p>
        <ul className="twocolumn-list">
            <li>
                Financial Services
            </li>
            <li>
                Bank Licensing
            </li>
            <li>
                VASP Licensing
            </li>
            <li>
                PSD2 Licensing
            </li>
            <li>
                Gambling Industry
            </li>
            <li>
                Gambling Licensing
            </li>
            <li>
                Financial Technologies
            </li>
            <li>
                Crowdfunding Licensing
            </li>
            <li>
                Licensing e-commerce platforms (DMA, DSA, DGA)
            </li>
            <li>
                Other Financial Services
            </li>
            <li>
                KIP and KIPE Licensing
            </li>
            <li>
                MIP Licensing
            </li>
            <li>
                Artificial Intelligence and Cryptocurrencies
            </li>
            <li>
                ART and CASP (MiCA) Licensing
            </li>
        </ul>
    </>
)
const weatlthAdvisorySericeContent = (
    <>
        At Infinity, our wealth advisory services offer personalized financial management and expansion strategies. Our team conducts thorough assessments of clients' financial situations and collaborates to create tailored strategies for wealth accumulation and preservation.
        We provide expert guidance on investment opportunities, asset allocation, tax optimization, estate planning, and more. Our approach includes ongoing portfolio monitoring and adjustments to align with market changes and clients' evolving needs.
    </>
)


const ServicesData = [
    {
        linkName: "BUSINESS STRATEGY",
        linkContent: businessStrategyContent,
        centered: true
    },
    {
        linkName: "DAO",
        linkContent: daoContent,
        centered: false,
    },
    {
        linkName: "Digital Transformation",
        linkContent: digitalTransformationContent,
    },
    {
        linkName: "Individual Tax Strategies",
        linkContent: individualTaxStrategiesContent
    },
    {
        linkName: "IP Strategy",
        linkContent: ipStrategyContent
    },
    {
        linkName: "KOL/ Influencer Compliance Support",
        linkContent: kolInfluencerComplianceSupportContent
    },
    {
        linkName: "Licensing",
        linkContent: licensingContent
    },
    {
        linkName: "Wealth Advisory Service",
        linkContent: weatlthAdvisorySericeContent
    }
]
import { useGSAP } from "@gsap/react";
import { gsap } from "../gsapConfig";

export default function HelpPage({ elementRef }) {

    useGSAP(() => {

        let mm = gsap.matchMedia();



        mm.add("(min-width: 991px)", () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".help",
                    start: "top 50%",
                    end: '100% bottom',
                    scrub: 1,
                    // toggleActions: "play none none none"
                }
            });

            tl.to(".background", { backgroundColor: "#440511", duration: 1 }, 0);
            tl.fromTo(".help h2", { y: 50, opacity: 0.1 }, { y: 0, opacity: 1, duration: 3, delay: 1 });
            tl.fromTo(".help .first-content", { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 3, delay: 3 }, 0);
            tl.fromTo(".help .second-content", { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 3, delay: 4 }, 0);


            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".help",
                    start: "-10% center",
                    end: '30%',
                    scrub: 1,
                }
            });

            tl2.to(".team-line-1", { x: -300, duration: 1 }, 0);
            tl2.to(".team-line-2", { x: -270, y: "7vh", duration: 1 }, 0);

        })
        mm.add("(max-width: 990px)", () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".help",
                    start: "top 50%",
                    end: '100% bottom',
                    scrub: 1,
                    // toggleActions: "play none none none"
                }
            });

            tl.to(".background", { backgroundColor: "#490511", duration: 1 }, 0);
            tl.fromTo(".help h2", { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 3, delay: 1 });
            tl.fromTo(".help .first-content", { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 3, delay: 3 }, 0);
            tl.fromTo(".help .second-content", { y: 30, opacity: 0 }, { y: 0, opacity: 1, duration: 3, delay: 4 }, 0);

        });

    })


    return (
        <section className="help" id="help">

            <div className="content-wrap">
                <h2>How can we <span>help</span>?</h2>
                <div className="content">
                    <div className="empty">
                    </div>
                    <div className="first-content">
                        <p className="paragraph-1">If you're facing questions like which regulations apply to your project, how international expansion affects tax issues, or ensuring token compliance for CEX listing without tax implications, we're here to help.</p>
                    </div>
                    <div className="second-content">
                        <p className="paragraph-2">If you're facing questions like which regulations apply to your project, how international expansion affects tax issues, or ensuring token compliance for CEX listing without tax implications, we're here to help. Need to navigate fundraising with equity involved or secure founders and tokenomies with SAFTS, SAFEs, or Token Warrants?</p>
                        <p className="paragraph-3">Heard about MiCA and licensing but unsure how it applies? Wondering about the best jurisdiction or structuring a legit DAO? We've got you covered. Let us handle the complexities while you focus on your project's success.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
import {Link} from 'react-router-dom';
import FooterLogo from '../assets/images/footer/footer-logo.svg';
import ICSLogo from '../assets/images/footer/International corporate services.svg';
import GmailIcon from '../assets/images/footer/gmail.svg';
import TelegramIcon from '../assets/images/footer/telegram.svg';

import { useGSAP } from '@gsap/react';
import { gsap } from '../gsapConfig';
 
export default function Footer(){

    useGSAP(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#footer",
                start: "top 110%",
                end: '100% bottom',
                toggleActions: "play none none none"
            }
        });
        
        tl.fromTo("footer .logos-wrap", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 })
        tl.fromTo("footer .links-wrap", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, 0)
        tl.fromTo("footer .address-socials-wrap", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, 0)
        
        const footerHeight = document.getElementById('footer').offsetHeight;

        const mm = gsap.matchMedia();

        mm.add("(min-width: 991px)", () => {
            let tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: "#footer",
                    start: "top bottom",
                    end: 'bottom bottom',
                    scrub: true,
                    onUpdate: (self) => {
                        let calculatedY = self.progress * (footerHeight);
    
                        tl2.to(".team-line-1", { bottom: (calculatedY), duration: 0.1 }, 0);
                        tl2.to(".team-line-2", { bottom: (calculatedY), duration: 0.1 }, 0);
                        tl2.to(".team-line-3", { bottom: (calculatedY), duration: 0.1 }, 0);  
                    }
                }
            });
    
        })
    })

    return (
        <footer id="footer">
            <div className="container">
                <div className="top-wrap">
                    <div className="logo-text-wrap">
                        <Link className='link' to='/'>
                            <img src={FooterLogo} alt="logo" /> 
                        </Link>
                        <p className="text">
                            International Corporate Services
                        </p>
                    </div>
                    <div className="links-wrap">
                        <Link className='link'>about us</Link>
                        <Link className='link'>our services</Link>
                        <Link className='link'>our core team</Link>
                        <Link className='link'>contact us</Link>
                        <Link className='link' to="/privacy-policy">privacy policy</Link>
                        <Link className='link' to="/tos">terms of service</Link>
                    </div>
                    <div className="address-socials-wrap">
                        <p className="address">
                            INFINITY ICS LTD<br/>20 Wenlock Road<br/> N1 7GU, London, UK
                        </p>
                        <div className="socials">
                            <a href="mailto:hello@infinityics.com" className="gmail">
                                <img src={GmailIcon} alt="gmail-ion" className="logo" />
                                <div className="text">
                                    hello@infinityics.com
                                </div>
                            </a>
                            <a href="https://t.me/@dzablocka" className="telegram" target="_blank" rel='noreferrer'>
                                <img src={TelegramIcon} alt="telegramicon" className="logo" />
                                <div className="text">
                                    @dzablocka
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bottom-wrap">
                    <p className='copywright'>2024 All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
}

import Home from "./pages/Home";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ErrorPage from "./pages/ErrorPage";
import { createBrowserRouter } from "react-router-dom";



const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />
    },
    {
        path: "/tos",
        element: <TermsOfService />,
        errorElement: <ErrorPage />
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
        errorElement: <ErrorPage />
    },
   
]);


export default router;
import { gsap } from '../gsapConfig';
import { useGSAP } from '@gsap/react';

const Banner = ({ data }) => {

  useGSAP(() => {


    let mm = gsap.matchMedia();

    mm.add("(min-width: 991px)", () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".banner",
          start: "center center",
          end: '300% bottom',
          pin: true,
          pinSpacing: true,
          scrub: 1,
        },
        onEnter: () => {
          //reset the preloaders final state
          tl.to('.preloader #circle', { scale: 1.05, y: 100, duration: 0.1 });
          tl.to('.preloader .content', { y: 0, opacity: 1, duration: 0.1 }); 
        }
      });

      tl.to("#header", { top: 0, duration: 1 }, 0);
      tl.fromTo(".banner .heading", { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2 }, 0);
      tl.fromTo(".line", { opacity: 0 }, { opacity: 1, duration: 1 }, 0);
      tl.fromTo(".banner .subheading", { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1");
      // tl.to("#line2", { opacity: 1, duration: 2 }, "+=2");
      tl.fromTo(".banner .description", { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+-1");
      // tl.to("#line3", { opacity: 1, duration: 2 }, "+=2");

    });
    mm.add("(max-width: 990px)", () => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".banner",
          start: "top top",
          end: '+=200',
          pin: ".banner",
          scrub: 1,
          onEnter: () => { document.getElementById("header").classList.add("show") }
        }
      });

      tl.fromTo(".banner .heading", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, 0)
      tl.fromTo(".banner .subheading", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1 }, "+=1")
      tl.fromTo(".banner .description", { y: 50, opacity: 0 }, { y: 0, opacity: 1, duration: 1}, "+=1")
      tl.to(".banner .description", { y: 0, opacity: 1, duration: 2}, "+=1")
    });

  })

  return (
    <>
      <div className="banner" id="banner">
        <div className="inner ">
          <div className="heading" dangerouslySetInnerHTML={{ __html: data.heading }} />
          <div className="banner-content">
            <div className="subheading">{data.subHeading}</div>
            <div className="description">{data.description}</div>
          </div>
        </div>

        <svg xmlns="http://www.w3.org/2000/svg" className="line" id="line1" viewBox="0 0 1440 648" fill="none">
          <g clip-path="url(#clip0_2033_197)">
            <path d="M686 726C686 658.333 686 509.1 686 453.5C686 397.9 731 397.5 755.5 397.5C870.333 397.5 1114.3 397.5 1171.5 397.5C1228.7 397.5 1233 372.5 1233 336V43.5002C1233 27.5 1239.3 2 1274.5 2C1309.7 2 1419.17 2 1469.5 2" stroke="#C1001D" stroke-width="2" />
          </g>
          <defs>
            <clipPath id="clip0_2033_197">
              <rect width="1440" height="648" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" className="line" id="line2" viewBox="0 0 1440 607" fill="none">
          <g clip-path="url(#clip0_2033_197)">
            <path d="M792 726C792 658.333 792 509.1 792 453.5C792 397.9 835 397.5 861.5 397.5C976.333 397.5 1220.3 397.5 1277.5 397.5C1334.7 397.5 1339 362 1339 336V43.5002C1339 20.5 1345.3 2 1380.5 2C1415.7 2 1525.17 2 1575.5 2" stroke="#C1001D" stroke-width="2" />
          </g>
          <defs>
            <clipPath id="clip0_2033_197">
              <rect width="1440" height="607" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" className="line" id="line3" viewBox="0 0 1440 349" fill="none">
          <g clip-path="url(#clip0_2033_197)">
            <path d="M1503.5 -327C1503.5 -259.333 1503.5 -110.1 1503.5 -54.5C1503.5 1.1 1459.5 1.49997 1434 1.49997C1319.17 1.49997 1075.2 1.49997 1018 1.49997C960.8 1.49997 956.5 25.5 956.5 62.9998V355.5C957.333 369.333 950.2 397 915 397C879.8 397 770.333 397 720 397" stroke="#C1001D" stroke-width="2" />
          </g>
          <defs>
            <clipPath id="clip0_2033_197">
              <rect width="1440" height="349" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export default Banner;
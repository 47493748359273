import { useEffect, useState } from 'react';
import cross from '../assets/images/Team Member Details Blurb/cross.svg'



export default function TeamMemberDetail({ open, data, onClose }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            let element = document.getElementById("our-team")
            if (element) {
                const rect = element.getBoundingClientRect();
                const height = rect.bottom - rect.top
                const isVisible = (
                    rect.top <= 500 && rect.top >= 300 - height
                );
                setIsVisible(isVisible);
                if (!isVisible) {
                    onClose();  
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        // Initial check on component mount
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (

        <>
            {open && isVisible && (
                <div className="global-overlay" onClick={onClose}></div>
            )}
            <div className={open && isVisible ? " team-member-description-blurb active" : "team-member-description-blurb"}>
                <div className="cross" onClick={onClose}>
                    <img src={cross} alt="" />
                </div>
                {data && (
                    <div className="container">
                        <h5 className="name">
                            {data.name}
                        </h5>
                        <p className="position" dangerouslySetInnerHTML={{ __html: data.position }} />
                        <p className="description">
                            {data.description}
                        </p>
                        <div className="socials">
                            <a href={data.telegramLink} className="telegram icons" target='_blank' rel="noreferrer">
                                <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.7646 1.65948L14.256 13.49C14.0668 14.3249 13.5732 14.5327 12.8719 14.1394L9.04959 11.3228L7.20525 13.0966C7.00115 13.3007 6.83045 13.4714 6.43709 13.4714L6.7117 9.57862L13.7959 3.17726C14.1039 2.90265 13.7291 2.7505 13.3172 3.02511L4.55936 8.53956L0.789042 7.35948C-0.0310748 7.10343 -0.0459186 6.53937 0.959746 6.14601L15.707 0.464561C16.3898 0.208506 16.9873 0.616709 16.7646 1.65948Z" fill="white" />
                                </svg>
                            </a>
                            <a href={data.instagramLink} className="insta icons" target='_blank' rel="noreferrer">
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.50356 5.23245C7.14341 5.23245 5.2397 7.13616 5.2397 9.49631C5.2397 11.8565 7.14341 13.7602 9.50356 13.7602C11.8637 13.7602 13.7674 11.8565 13.7674 9.49631C13.7674 7.13616 11.8637 5.23245 9.50356 5.23245ZM9.50356 12.2684C7.97837 12.2684 6.73149 11.0252 6.73149 9.49631C6.73149 7.96741 7.97466 6.72424 9.50356 6.72424C11.0325 6.72424 12.2756 7.96741 12.2756 9.49631C12.2756 11.0252 11.0288 12.2684 9.50356 12.2684ZM14.9364 5.05803C14.9364 5.61096 14.4911 6.05256 13.9418 6.05256C13.3889 6.05256 12.9473 5.60725 12.9473 5.05803C12.9473 4.50881 13.3926 4.0635 13.9418 4.0635C14.4911 4.0635 14.9364 4.50881 14.9364 5.05803ZM17.7604 6.06741C17.6973 4.73518 17.393 3.5551 16.417 2.58284C15.4448 1.61057 14.2647 1.30627 12.9325 1.23948C11.5594 1.16155 7.44399 1.16155 6.07095 1.23948C4.74243 1.30256 3.56235 1.60686 2.58638 2.57913C1.6104 3.55139 1.30981 4.73147 1.24302 6.0637C1.16509 7.43674 1.16509 11.5522 1.24302 12.9252C1.3061 14.2574 1.6104 15.4375 2.58638 16.4098C3.56235 17.3821 4.73872 17.6864 6.07095 17.7531C7.44399 17.8311 11.5594 17.8311 12.9325 17.7531C14.2647 17.6901 15.4448 17.3858 16.417 16.4098C17.3893 15.4375 17.6936 14.2574 17.7604 12.9252C17.8383 11.5522 17.8383 7.44045 17.7604 6.06741ZM15.9866 14.3985C15.6971 15.1258 15.1368 15.6862 14.4057 15.9793C13.311 16.4135 10.7133 16.3133 9.50356 16.3133C8.2938 16.3133 5.69243 16.4098 4.60142 15.9793C3.87407 15.6899 3.31372 15.1295 3.02056 14.3985C2.58638 13.3037 2.68657 10.7061 2.68657 9.49631C2.68657 8.28655 2.59009 5.68518 3.02056 4.59417C3.31001 3.86682 3.87036 3.30647 4.60142 3.01331C5.69614 2.57913 8.2938 2.67932 9.50356 2.67932C10.7133 2.67932 13.3147 2.58284 14.4057 3.01331C15.1331 3.30276 15.6934 3.86311 15.9866 4.59417C16.4208 5.68889 16.3206 8.28655 16.3206 9.49631C16.3206 10.7061 16.4208 13.3074 15.9866 14.3985Z" fill="white" />
                                </svg>
                            </a>
                            <a href={data.linkedinLink} className="linkedin icons" target='_blank' rel="noreferrer">
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.90883 16.625H1.46211V5.52558H4.90883V16.625ZM3.18361 4.01152C2.08146 4.01152 1.1875 3.09863 1.1875 1.99648C1.1875 1.46708 1.3978 0.959358 1.77215 0.585014C2.14649 0.21067 2.65421 0.000366211 3.18361 0.000366211C3.71302 0.000366211 4.22073 0.21067 4.59508 0.585014C4.96942 0.959358 5.17973 1.46708 5.17973 1.99648C5.17973 3.09863 4.28539 4.01152 3.18361 4.01152ZM17.8088 16.625H14.3695V11.2219C14.3695 9.93417 14.3435 8.28281 12.5775 8.28281C10.7855 8.28281 10.5109 9.68183 10.5109 11.1291V16.625H7.06785V5.52558H10.3736V7.03964H10.4218C10.882 6.16757 12.006 5.24726 13.683 5.24726C17.1713 5.24726 17.8125 7.54433 17.8125 10.5279V16.625H17.8088Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
}

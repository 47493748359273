import Header from "../components/Header";
import {useEffect, useState } from 'react';
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { smoothScroll } from "../gsapConfig";

export default function PrivacyPolicy(){
    const [open, setOpen] = useState(false);
    useEffect(()=>{
        smoothScroll(false)
    }, [])
    return (
        <>
            <Header otherThanHome={true} openContact={() => setOpen(true)}/>
            <div className="privacypolicy">
                <div className="container">
                    <div className="heading-wrap">
                        <div className="heading">
                            Privacy Policy
                        </div>
                        <div className="text">
                            of the infinityics.com website
                        </div>
                    </div>
                    <div className="content-wrap">
                        {
                            PrivacyPolicyData.map((link) => {
                                return (
                                    <>
                                        <div className="policy">
                                            <div className="heading">
                                                {link.ppName}
                                            </div>
                                            <div className="content">
                                                {link.ppContent}
                                            </div>
                                        </div>
                                    </>
                                )
                            } )
                        }
                    </div>
                </div>
            </div>
            <Contact open={open} onClose={() => setOpen(false)} otherThanHome={true}/>
            <Footer />
        </>
    );

}


const lastUpdatedcontent = (
    <>
        <p>10 June, 2024</p>
    </>
)

const introductionContent = (
    <>
        <p>
            This Privacy Policy describes how we, INFINITY ICS LTD. registered in United Kingdom, company number […], address: 20 Wenlock Road, N1 7GU, London, United Kingdom (“Infinity”, “we”, “our”, “us”), collect and use your personal data in connection with your use of the Website and the Services.
        </p>
        <p>
            We provide the Website and the Services subject to the Terms. Please read the Terms before accessing or using the Website or the Services.
        </p>
    </>
)

const childrenContent = (
    <>
      <p>
        The Website and Services are restricted to persons who are at least 18 years of age. We do not knowingly collect personal data from people who are less than 18 years of age in connection with the Website or the Services.
      </p>
    </>
)

const definitionContent = (
    <>
        <p>
            <span>personal data, controller, processor, processing,</span> and other terms relating to personal data not defined here have the meaning as defined in the UK GDPR.
        </p>
        <p>
            <span>GDPR –</span> General Data Protection Regulation 2016/679 of 27 April 2016.
        </p>
        <p>
            <span>Infinity (“we”, “our”, “us”) –</span> INFINITY ICS LTD. registered in United Kingdom, company number […], address: 20 Wenlock Road, N1 7GU, London, United Kingdom
        </p>
        <p>
            <span>Privacy Policy –</span> this Privacy Policy.
        </p>
        <p>
            <span>Services –</span> the Services as defined in the Terms.
        </p>
        <p>
            <span>Terms –</span> the Terms and Conditions available at
        </p>
        <p>
            <span>UK DPA –</span> UK Data Protection Act 2018 as amended.
        </p>
        <p>
            <span>UK GDPR –</span> GDPR as it forms part of the law of England and Wales, Scotland, and Northern Ireland by virtue of section 3 of the European Union (Withdrawal) Act 2018, as implemented by the UK DPA.
        </p>
        <p>
            <span>Website –</span> the Website as defined in the Terms. 
        </p>
    </>
)

const controllerContactDetailsContent = (
    <>
        <p>
            We are the controller of your personal data. You can contact us:
        <ul>
            <li>
                by email at:
            </li>
            <li>
                in writing to our address: 20 Wenlock Road, N1 7GU, London, United Kingdom.
            </li>
        </ul>
        </p>
    </>
)

const informationContent = (
    <>
        <div className="table-wrap">
            <p>
                We collect or use the following information in accordance with the schedule below.
            </p>
            <div className="table">
                <div className="headings-wrap">
                    <h4>
                        GOAL OF PROCESSING
                    </h4>
                    <h4>
                        PERSONAL DATA
                    </h4>
                    <h4>
                        LEGAL BASES
                    </h4>
                </div>
                <div className="row-data first">
                    <p className="bold">
                        To provide our Services
                    </p>
                    <ul>
                        <li>
                            Names and contact details
                        </li>
                        <li>
                            Addresses
                        </li>
                        <li>
                            Website user information (including IP addresses)
                        </li>
                        <li>
                            Records of meetings and decisions
                        </li>
                        <li>
                            Identification documents
                        </li>
                        <li>
                            Information relating to compliments or complaints
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Contract
                        </li>
                        <li>
                            Legal obligation
                        </li>
                    </ul>
                </div>
                <div className="row-data second">
                    <p className="bold">
                        To comply with legal requirements
                    </p>
                    <ul>
                        <li>
                            Names and contact details
                        </li>
                        <li>
                            Identification documents
                        </li>
                        <li>
                            Financial transaction information
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Legal obligation
                        </li>
                    </ul>
                </div>
                <div className="row-data third">
                    <p className="bold">
                        To conduct marketing activities or to update our   Services
                    </p>
                    <ul>
                        <li>
                            Names and contact details
                        </li>
                        <li>
                            Addresses
                        </li>
                        <li>
                            Marketing preferences
                        </li>
                        <li>
                            Location data   
                        </li>
                        <li>
                            Website user information (including IP addresses)
                        </li>
                        <li>
                            Records of consent (where appropriate)
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Consent (where appropriate)
                        </li>
                        <li>
                            Legitimate interest(s):
                        </li>
                        <p>
                            We use your personal data to for marketing purposes or to update our Services. The legal ground for such processing is our legitimate interest, which consists of improving our Services and promoting them and our brand, including by direct marketing. Where required by law, we will be conducting direct marketing activities only with your consent.
                        </p>
                    </ul>
                </div>
                <div className="row-data fourth">
                    <p className="bold">
                        To prevent crime, prosecute offenders, or defend against legal action
                    </p>
                    <ul>
                        <li>
                            Names and contact details
                        </li>
                        <li>
                            Addresses
                        </li>
                        <li>
                            Website user information (including IP addresses)
                        </li>
                        <li>
                            Records of meetings and decisions
                        </li>
                        <li>
                            Identification documents
                        </li>
                        <li>
                            Information relating to compliments or complaints
                        </li>
                        <li>
                            Financial transaction information
                        </li>
                        <li>
                            Marketing preferences
                        </li>
                        <li>
                            Location data
                        </li>
                        <li>
                            Records of consent, where appropriate
                        </li>
                    </ul>
                    <p>
                        against claims. The legal ground for such processing is our legitimate interest, which consists of the protection of our legal rights.
                    </p>
                </div>
            </div>
        </div>
    </>
)

const getPersonalDataContent = (
    <>
        <ul>
            <li>
                You directly (when you use our Website, our Services or interact with us via online communicators or online platforms)
            </li>
            <li>
                Online communicators or online platforms (when you interact with us via online communicators such as Telegram or online platforms such as LinkedIn)
            </li>
            <li>
                Publicly available sources (such as business registers)
            </li>
        </ul>
    </>
)

const keepPersonalDataContent = (
   <>
        <div className="table-wrap">
            <p>
                We store your personal data in accordance with the schedule below.
            </p>
            <div className="table two-column">
                <div className="headings-wrap">
                    <h4>
                        PERSONAL DATA    
                    </h4>
                    <h4>
                        LEGAL BASES
                    </h4>
                </div>
                <div className="row-data first">
                    <p className="bold">
                        To provide our Services
                    </p>
                    <p className="bold second-column-text">
                        5 years
                    </p>
                </div>
                <div className="row-data second">
                    <p className="bold">
                        To comply with legal requirements
                    </p>
                    <p className="bold second-column-text">
                        5 years
                    </p>
                </div>
                <div className="row-data third">
                    <p className="bold">
                        To conduct marketing activities or to update our Services
                    </p>
                    <p className="bold second-column-text">
                        5 years
                    </p>
                </div>
                <div className="row-data fourth">
                    <p className="bold">
                        To prevent crime, prosecute offenders, or defend against legal action
                    </p>
                    <p className="bold second-column-text">
                        5 years
                    </p>
                </div>
            </div>
        </div>
   </>
)

const sharePersonalDataContent = (
    <>
        <ul>
            <li>
                Our business partners (including service providers and contractors), such as marketing and advertising services providers, analytical tools providers, data storage providers;
            </li>
            <li>
                Professional or legal advisors
            </li>
            <li>
                Professional consultants
            </li>
            <li>
                Other third parties if admissible under applicable law (e.g. with your consent).
            </li>
        </ul>
    </>
)

const sharePersonalDataUSAContent = (
    <>
        <p>
            Where necessary, we may transfer personal information outside of the UK. When doing so, we comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.
        </p>
        <p>
            Where necessary, our data processors may share personal information outside of the UK. When doing so, they comply with the UK GDPR, making sure appropriate safeguards are in place. Please contact us for more information.
        </p>
    </>
)

const providePersonalDataContent = (
    <>
        <p>
            In some cases, provision of your personal data is mandatory by law or necessary to carry out your request or to perform a contract we have with you. If you don’t provide us with your personal data in such situations, we may not be able to carry out your request, perform a contract with you (or enter into it) or comply with the law. In some cases, this may mean that we will terminate the contract or stop our engagement with you. For example, if you do not provide your personal data necessary for the complaint procedure, we may not be able to handle your complaint.
        </p>
        <p>
            In other cases, provision of your personal data is voluntary. If you don’t provide us with your personal data in such situations, we may not be able to carry out your request or achieve our goal. For example, if you do not share your contact details with us, we may not be able to contact you.
        </p>
    </>
)

const dataProtectionRightContent = (
    <>
        <p>
            <span>Your right of access -</span> You have the right to ask us for copies of your personal data.
        </p>
        <p>
            <span>Your right to rectification -</span> You have the right to ask us to rectify personal data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
        </p>
        <p>
            <span>Your right to erasure -</span> You have the right to ask us to erase your personal data in certain circumstances.
        </p>
        <p>
            <span>Your right to restriction of processing -</span> You have the right to ask us to restrict the processing of your personal data in certain circumstances.
        </p>
        <p>
            <span>Your right to object to processing -</span> You have the right to object to the processing of your personal data in certain circumstances.
        </p>
        <p>
            <span>Your right to data portability -</span> You have the right to ask that we transfer the personal data you gave us to another organisation, or to you, in certain circumstances.
        </p>
        <p>
            <span>Your right to withdraw consent –</span> When we use consent as our lawful basis you have the right to withdraw your consent.
        </p>
        <p>
            You don’t usually need to pay a fee to exercise your rights. If you make a request, we have one calendar month to respond to you. To make a data protection rights request, please contact us using the contact details at the top of this privacy notice.
        </p>
    </>
)

const complainContent = (
    <>
        <p>
            If you have any concerns about our use of your personal data, you can make a complaint to us using the contact details at the top of this privacy notice.
        </p>
        <p>
            If you remain unhappy with how we’ve used your data after raising a complaint with us, you can also complain to the ICO.
        </p>
        <p>
            The ICO’s address:
        <ul>
            <li className="bold">
                Information Commissioner’s Office
            </li>
            <li className="bold">
                Wycliffe House
            </li>
            <li className="bold">
                Water Lane
            </li>
            <li className="bold">
                Wilmslow
            </li>
            <li className="bold">
                Cheshire
            </li>
            <li className="bold">
                SK9 5AF
            </li>
            <li className="bold">
                Helpline number: 0303 123 1113
            </li>
            <li className="bold">
                Website: https://www.ico.org.uk/make-a-complaint
            </li>
        </ul>
        </p>
    </>
)


const PrivacyPolicyData = [
    {
        ppName: "LAST UPDATE",
        ppContent: lastUpdatedcontent,
    },
    {
        ppName: "Introduction",
        ppContent: introductionContent,
    },
    {
        ppName: "Children",
        ppContent: childrenContent,
    },
    {
        ppName: "Definitions",
        ppContent: definitionContent,
    },
    {
        ppName: "1. Controller and contact details",
        ppContent: controllerContactDetailsContent,
    },
    {
        ppName: "2. What information we collect, use, and why",
        ppContent: informationContent,
    },
    {
        ppName: "3. Where we get personal data from",
        ppContent: getPersonalDataContent,
    },
    {
        ppName: "4. How long we keep your personal data",
        ppContent: keepPersonalDataContent,
    },
    {
        ppName: "5. Who we share personal data with",
        ppContent: sharePersonalDataContent,
    },
    {
        ppName: "6. Sharing personal data outside the UK",
        ppContent: sharePersonalDataUSAContent,
    },
    {
        ppName: "7. Requirement to provide personal data",
        ppContent: providePersonalDataContent,
    },
    {
        ppName: "8. Your data protection rights",
        ppContent: dataProtectionRightContent,
    },
    {
        ppName: "9. How to complain",
        ppContent: complainContent,
    },
]
import { useRef, useState } from 'react';
import cross from '../assets/images/Team Member Details Blurb/cross.svg'
import emailjs from '@emailjs/browser'
import { enqueueSnackbar } from 'notistack';
import { startScroll, stopScroll } from '../gsapConfig';

export default function Contact({ open, onClose, otherThanHome }) {
    const formRef = useRef();
    const [isSuccess, setIsSuccess] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        const form = new FormData(formRef.current);
        //validate form
        if (!form.get('acceptTerms')) {
            enqueueSnackbar('Please accept the terms', { variant: 'error' });
            return;
        }
        if (!form.get('full_Name')) {
            enqueueSnackbar('Please enter your name', { variant: 'error' });
            return;
        }
        if (!form.get('email')) {
            enqueueSnackbar('Please enter your email', { variant: 'error' });
            return;
        }
        if (!form.get('message')) {
            enqueueSnackbar('Please enter your message', { variant: 'error' });
            return;
        }

        emailjs.sendForm(
            'service_g3gr6me',
            'template_32cd7mk',
            formRef.current,
            {
                publicKey: 'ke9MLeKoCUWbVFFKb'
            }
        ).then((result) => {
            enqueueSnackbar('Email sent successfully', { variant: 'success' });
            setIsSuccess(true);
        }).catch((error) => {
            enqueueSnackbar('Failed to send email', { variant: 'error' });
        })
    }
    function getClassList(){
        var classes = "contact"
        if(otherThanHome){
            classes = classes + ' notHome'
        }
        if(open){
            classes = classes + ' active'
        }

        return classes;
    }

    const handleCLose = () => {
        document.body.classList.remove('no-scroll');
        startScroll()
        onClose();
    }
    return (
        <>
            {open && (<div className="global-overlay d-sm-none"></div>)}
            <section id='contact' className={getClassList()}>
                <div className="cross" onClick={handleCLose}>
                    <img src={cross} alt="" />
                </div>
                <div className="contact-wrap">
                    <div className="contact-inner">
                        {isSuccess && (
                            <div className="heading thankyou-heading">
                                <h4>
                                    Thank you!
                                </h4>
                                <p className='thank-you-statement'>
                                We've received your message,<br />we will reach out to you shortly.
                                </p>
                                <button className='back-to-home-button' onClick={onClose}>
                                    Back To Home Page
                                </button>
                            </div>
                        )}
                        {!isSuccess && (
                            <>
                                <div className="heading">
                                    <h4>
                                        Contact us
                                    </h4>
                                    <p>
                                        Our mission: elevate regulatory awareness and execute projects efficiently, ensuring legal compliance.
                                    </p>
                                </div>
                                <div className="form">
                                    <h5>
                                        CONTACT US
                                    </h5>
                                    <form ref={formRef} onSubmit={sendEmail} >
                                        <>
                                            <input class="form-control-input Name" type="text" placeholder="Name" name="full_Name" required />
                                            <input class="form-control-input email" type="email" placeholder="Address e-mail" name="email" required />
                                            <textarea className='message' name="message" placeholder='Message' rows="2" required />
                                            <div className="check">
                                                <input type="checkbox" name="acceptTerms" />
                                                <label class="form-control checkbox-label">
                                                    I agree to the processing of data in accordance with <a href="/">Privacy Policy</a>.
                                                </label>
                                            </div>
                                            <button type="submit" class="button submit" id="send">Send Message</button>
                                        </>
                                    </form>
                                </div>
                                <div className="address-wrap">
                                    <p className='address-title'>ADDRESS</p>
                                    <p className="address">INFINITY ICS LTD<br />20 Wenlock Road<br />N1 7GU, London, UK</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}
import Header from "../components/Header";
import HelpPage from "../components/HelpPage"
import OurCoreTeam from "../components/OurCoreTeam"
import Preloader from "../components/PreLoader";
import Service from "../components/Service";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import { useEffect, useState } from 'react';
import Contact from "../components/Contact";
import desktopBackground from '../assets/images/desktopBackground.svg'
import mobileBackground from '../assets/images/mobileBackground.svg'
import Lenis from "lenis";
import { ScrollTrigger } from "gsap/all";
import gsap from "gsap";
import { smoothScroll } from '../gsapConfig';
import { useGSAP } from "@gsap/react";

export default function Home() {
  const [loading, setLoading] = useState(true); // Pass 5000 ms as the minimum load time
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  gsap.registerPlugin(ScrollTrigger)

  const bannerData = {
    heading: `<span>Empowering</span></br>Tech Startups</br>& Traditional Businesses`,
    subHeading: "Your Regulatory & Strategic Advisory Partner for Digital Transformation",
    description: "We are unlocking prosperity for individuals through our international wealth concierge service, and crafting efficient corporate and fund structures tailored to businesses' needs"
  }
  useEffect(() => {
    smoothScroll(true)
  }, [])

  return (
    <>
      <Header openContact={() => setOpen(true)} />
      <div className="background">
        <img className="desktopBackground" src={desktopBackground} alt="desktopBackground" />
        <img className="mobileBackground" src={mobileBackground} alt="mobileBackground" />
        <div className="team-line-1">
          <div className="line1"></div>
          <div className="line2"></div>
        </div>
        <svg className="team-line-3" viewBox="0 0 1440 218" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2011_66)">
            <path d="M860 502.002C860 434.336 860 285.102 860 229.502C860 173.902 808 173.502 808 173.502C693.167 173.502 440.2 173.502 383 173.502C330.5 173.502 330.5 122.502 330.5 122.502V43.502C331.333 29.6686 324.2 2.00183 289 2.00183C253.8 2.00183 -609.167 2 -659.5 2" stroke="#C1001D" stroke-width="2" />
          </g>
          <defs>
            <clipPath id="clip0_2011_66">
              <rect width="1440" height="218" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div className="team-line-2"></div>
      </div>
      <Preloader animationPlay={loading} setLoading={(value) => setLoading(value)} />
      {!loading && (
        <>
          <Banner data={bannerData} />
          <Service refresh={refresh} setRefresh={(value) => setRefresh(value)} />
          <OurCoreTeam refresh={refresh} />
          <HelpPage />
          <Contact open={open} onClose={() => setOpen(false)} />
          <Footer />
        </>
      )}
    </>

  )

}
import { Link } from "react-router-dom";
import HeaderLogo from "../assets/images/header/header-logo.svg";
import MobileIcon from "../assets/images/header/hamburger.svg";
import CrossIcon from "../assets/images/header/cross.svg";
import { useState } from "react";
import { scrollToSection, stopScroll, startScroll } from "../gsapConfig";
import { HashLink } from "react-router-hash-link";
export default function Header({ openContact, otherThanHome }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <header id="header" className={otherThanHome && "default-open white"}>
        <div className="container">
          <Link className="logo-wrap" to="/">
            <img className="logo" src={HeaderLogo} alt="headerLogo" />
          </Link>
          {!otherThanHome &&
            <div className="links-wrap">
              {Links.map((link, index) => (
                <HashLink
                  className="link"
                  smooth
                  to={link?.scrollTo}
                >
                  {link.title}
                </HashLink>
              ))}
            </div>
          }
          <div className="contact-wrap">
            <Link className="contact-button" onClick={() => {
              document.body.classList.add('no-scroll');
              stopScroll()
              openContact();
            }}>
              Contact Us
            </Link>
          </div>
          {!otherThanHome &&
            <div className="mobileicon-wrap" onClick={() => {
                setOpen(!open)
                if(open){
                  startScroll()
                } else{
                  stopScroll()
                }
              }}>
              {open ? (
                <img src={CrossIcon} alt="cross" className="crossicon" />
              ) : (
                <img src={MobileIcon} alt="MobileIcon" />
              )}
            </div>
          }
        </div>
      </header>
      {!otherThanHome &&
        <div className={open ? "mobile-menu active" : "mobile-menu"}>
          <div className="inner">
            <div className="links-wrap">
              {Links.map((link, index) => (
                <Link
                  className="link"
                  onClick={() => {
                    setOpen(false);
                    scrollToSection(link?.scrollTo);
                    startScroll();
                  }}
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              ))}
            </div>
            <div className="contact-wrap">
              <Link className="contact-button" onClick={() => {
                setOpen(false);
                scrollToSection('#contact');
                startScroll();
              }}>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      }
    </>
  );
}

const Links = [
  {
    link: "/about",
    title: "About",
    scrollTo: "#banner",
  },
  {
    link: "/services",
    title: "Our Services",
    scrollTo: "#services",
  },
  {
    link: "/team",
    title: "Our Core Team",
    scrollTo: "#our-team",
  },
];

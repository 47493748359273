import { useRef, useState } from "react";
import FirstMember from '../assets/images/Our Core Team/Dorota.png'
import SecondMember from '../assets/images/Our Core Team/Aleksandra.png'
import TeamMemberDetail from "./TeamMemberDetail";
import { useGSAP } from "@gsap/react";
import { gsap, scrollRefresh, startScroll, stopScroll } from "../gsapConfig";
import $ from 'jquery';

export default function OurCoreTeam({ refresh }) {
    const [selectedMember, setSelectedMember] = useState(null)
    const [open, setOpen] = useState(false);

    const headingRef = useRef();
    const contentRef = useRef();

    const handleOpen = (memberIndex) => {
        setOpen(true);
        setSelectedMember(memberIndex)
        stopScroll()
    }
    const handleClose = () => {
        setOpen(false);
        setSelectedMember(null)
        startScroll()
    }

    useGSAP(() => {
        let mm = gsap.matchMedia();

        mm.add("(min-width: 991px)", () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".our-team",
                    start: "top top",
                    end: '250% bottom',
                    pin: true,
                    scrub: 1,
                }
            });
            tl.fromTo(".team-line-1", { opacity: 0 }, { opacity: 1, duration: 1 }, 0);
            tl.fromTo(".team-line-2", { opacity: 0 }, { opacity: 1, duration: 1 }, 0);
            tl.fromTo(".team-line-3", { opacity: 0 }, { opacity: 1, duration: 1 }, 0);

            tl.fromTo(headingRef.current, { y: 100, opacity: 0.1 }, { y: 0, opacity: 1, duration: 1 }, 0);
            tl.fromTo(contentRef.current, { y: 100, opacity: 0.1 }, { y: 0, opacity: 1, duration: 1 }, 0);
            tl.fromTo(".team-lines", { opacity: 0 }, { opacity: 1, duration: 2 }, 0);

            CoreTeamDetails.forEach((link, index) => {
                tl.fromTo(`.team-members .member:nth-child(${index + 1})`, { y: 200, opacity: 0 }, { y: 0, opacity: 1, duration: 4, delay: (index + 2) }, 0);
            });
            tl.to(".team-lines", { opacity: 0, duration: 1 });


            // let tl2 = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".our-team",
            //         start: "top top",
            //         // end: '90% top',
            //         scrub: 1,
                   
            //     }
            // });

           


        });

        // Optionally, add a fallback for screens smaller than 991px if needed
        mm.add("(max-width: 990px)", () => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: ".our-team",
                    start: "top center",
                    end: '+=700',
                    scrub: 1,
                }
            });

            tl.fromTo(headingRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 2, duration: 1 });
            tl.fromTo(contentRef.current, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 2, delay: 1 }, 0);

            CoreTeamDetails.forEach((link, index) => {
                tl.fromTo(`.team-members .member:nth-child(${index + 1})`, { y: 200, opacity: 0 }, { y: 0, opacity: 1, duration: 4, delay: (index + 2) }, `+=${index}`);
            });
        });

    }, { dependencies: [refresh] })

    return (
        <>

            <section className="our-team" id="our-team">
                <div className="container">
                    <div className="content">
                        {/* <svg className="team-lines" viewBox="0 0 1650 1328" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1194.5 1327.5C1194.5 1259.84 1194.5 1221.6 1194.5 1166C1194.5 1110.4 1125 1110 1125 1110C1010.17 1110 774.2 1110 717 1110C655.5 1110 655.5 1048.5 655.5 1048.5V437.002C656.333 423.169 649.2 395.502 614 395.502C578.8 395.502 187.333 395.5 137 395.5" stroke="#C1001D" stroke-width="2"/>
                            <path d="M80 0.00012207C80 67.6668 80 216.9 80 272.5C80 328.5 149.5 328.5 149.5 328.5C264.333 328.5 508.3 328.5 565.5 328.5C627 328.5 627 390 627 390V979C626.167 992.833 633.3 1020.5 668.5 1020.5C703.7 1020.5 1599.17 1020.5 1649.5 1020.5" stroke="#C1001D" stroke-width="2"/>
                            <path d="M1043 1327.5C1043 1259.83 1043 1110.6 1043 1055C1043 999.4 991 999 991 999C876.167 999 623.2 999 566 999C513.5 999 513.5 948 513.5 948V831C514.333 817.167 507.2 789.5 472 789.5C436.8 789.5 50.3333 789.5 0 789.5" stroke="#C1001D" stroke-width="2"/>
                        </svg> */}

                        <h2 ref={headingRef}>Our<span><br />Core</span> Team</h2>
                        <p ref={contentRef}>The main coordinators, Dorota Zabłocka and Aleksandra Jagielska, have extensive knowledge from their work on domestic and international projects. They advise companies on blockchain, crypto-economy, and innovative solutions.</p>
                    </div>
                    <div className="team-members">

                        {CoreTeamDetails.map((link, index) => {
                            return (
                                <>
                                    <div className="member" onClick={() => {
                                        handleOpen(index)
                                    }}>
                                        <img src={link.img} alt="" />
                                        <p className="name">{link.name}</p>
                                        <p className="description" dangerouslySetInnerHTML={{ __html: link.shortDescription }} />
                                        <p className="info">More info</p>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>
            <TeamMemberDetail open={open} data={CoreTeamDetails[selectedMember]} onClose={handleClose} />
        </>

    );
}

let CoreTeamDetails = [
    {
        img: FirstMember,
        name: "Dorota Zabłocka",
        shortDescription: "Founder | CEO<br />Regulatory Advisor & Lawyer",
        position: "Founder | CEO<br />Regulatory Advisor & Lawyer",
        description: "Dorota is a business-oriented lawyer and regulatory advisor with 10+ years of experience in complex assistance for technological companies. Specializing in corporate law, fintech, blockchain, DeFi and crowdfunding. She advises regulators, investors, startups, and businesses globally, particularly in web3, fintech, gaming, and AI. With extensive expertise in blockchain, she supports projects in data recording, asset marking, and crypto exchange entry. Named \"Top Women of Web3 in 2023, CEE\" in a study by IBM, Dorota is also a PhD student and lecturer, actively involved in tech conferences.",
        telegramLink: "https://t.me/@dzablocka",
        instagramLink: "https://www.instagram.com/dzablocka",
        linkedinLink: "https://www.linkedin.com/in/dorotazablockalaw/"
    },
    {
        img: SecondMember,
        name: "Aleksandra Jagielska",
        shortDescription: "Founder | COO<br />Regulatory Advisor & Lawyer",
        position: "Founder | COO<br />Regulatory Advisor & Lawyer",
        description: "Aleksandra specializes in FinTech regulation, new technology law, and civil law. She focused on blockchain legal aspects during her studies, earning recognition for her thesis. Aleksandra advises international web3 projects to ensure compliance with domestic and EU financial regulations. With experience in top law firms, including blockchain and DeFi teams, she offers broad regulatory support to clients, particularly in licensed and supervised areas.",
        telegramLink: "https://t.me/@AJagielska",
        instagramLink: "https://www.instagram.com/aajagielska/",
        linkedinLink: "https://pl.linkedin.com/in/aleksandra-jagielska-53356a211"
    }
]
import Header from "../components/Header";
import {useEffect, useState } from 'react';
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { smoothScroll } from "../gsapConfig";

export default function TermsOfService(){
    const [open, setOpen] = useState(false);
    useEffect(()=>{
        smoothScroll(false)
    }, [])
    return(
        <>
            <Header otherThanHome={true} openContact={() => setOpen(true)}/>
            <div className="termsofservice">
                <div className="container">
                    <div className="heading-wrap">
                        <div className="heading">
                            Terms of Service
                        </div>
                        <div className="text">
                            of the infinityics.com website
                        </div>
                    </div>
                    <ol className="content-wrap">
                        {
                            TermsOfServiceData.map((link) => {
                                return (
                                    <>
                                        <div className="list">
                                            <li className="heading">
                                                {link.tosName}
                                            </li>
                                            <div className="content">
                                                {link.tosContent}
                                            </div>
                                        </div>
                                    </>
                                )
                            } )
                        }
                    </ol>
                </div>
            </div>
            <Contact open={open} onClose={() => setOpen(false)} otherThanHome={true}/>
            <Footer />
        </>
    );

}


const generalProvisionsContent = (
    <>
        <ol>
            <li>
                These Terms of Service of the infinityics.com website (the "Terms") constitute a legally binding agreement between you (the “User”, "you", "your" etc.) and INFINITY ICS LTD. registered in United Kingdom, company number […], address: 20 Wenlock Road, N1 7GU, London, United Kingdom (“INFINITY”, “we”, “our”, “us”).
            </li>
            
            <li>
                The Terms apply to your use of the Website and Services made available to you through the Website. The Terms set out in particular:

                <ol className="child-list">
                    <li>
                        the types and scope of electronically supplied services;
                    </li>

                    <li>
                        the conditions for the supply of services by electronic means of communication, including:
                        <ol className="child-list">
                            <li>
                                technical requirements requisite for cooperation with the ICT Systems used by INFINITY;
                            </li>
                            <li>
                                a prohibition on providing illegal content by the User;
                            </li>
                            <li>
                                the conditions for making and terminating contracts of electronically supplied services;
                            </li>

                        </ol>
                    </li>
                    <li>
                        a complaint procedure.
                    </li> 
                </ol>

            </li>
            <li>
                The currently applicable and binding Terms are available on the Website in a form that enables their recording, storage, and reproduction by the User. The content of the Terms may be recorded and reproduced by the User using any technique on any media for purposes related to the use of the Website and the Services.
            </li>
            <li>
                Before accessing the Website or using any of the Services, you must carefully read and understand the Terms and the Privacy Policy. By accessing the Website or using any of the Services, you acknowledge that you have carefully read, understood, and agreed to be bound by the Terms and the Privacy Policy.
            </li>
            <li>
                Continued use of the Website and/or the Services is considered as a declaration of will to be bound by these Terms and acknowledgement of the Privacy Policy
            </li>
            <li className="bold">
                IF YOU DO NOT ACCEPT THESE TERMS IN THEIR ENTIRETY, YOU MUST NOT ACCESS OR USE THE WEBSITE OR THE SERVICES.
            </li>
        </ol>
    </>
)

const contactContent = (
    <>
        <ol>
            <li>
                You may contact INFINITY as regards the matters related to the Website or the Services using one of the following channels:

                <ol className="child-list">
                    <li>
                        email: [EMAIL ADDRESS];
                    </li>
                    <li>
                        telephone: [TELEPHONE NUMBER] (on Business Days from 9am to 5pm CET).
                    </li>
                </ol>

            </li>
            <li>
                We may communicate with you through the same contact channels as set forth in Section 2.1. above. If you use these communication channels, you agree that INFINITY may communicate with you through these channels in response.
            </li>
            <li>
                We may also communicate with you by posting information on the Website or by sending an email to the email address provided by you when using the Website or the Services.
            </li>
        </ol>
    </>
)

const technicalRequiremnentsContent = (
    <>
        <ol>
            <li>
                The technical requirements for the use of the Website and Services are as follows:

                <ol className="child-list">
                    <li>
                        an Internet-connected device with the latest operating system, Internet access and a current standard web browser (e.g. Chrome, Safari, Mozilla Firefox, Opera or Microsoft Edge);
                    </li>
                    <li>
                        cookies and JavaScript enabled in the web browser;
                    </li>
                    <li>
                        screen resolution when displaying the Website is at least 1280x720 pixel.
                    </li>
                </ol>

            </li>
        </ol>
    </>
)

const legalRequirementsContent = (
    <>
        <ol>
            <li>
                The legal requirements for the use of the Website and the Services are as follows:

                <ol className="child-list">
                    <li>
                        you must be at least 18 years old or of the legal age in accordance with the Applicable Law at the time of accessing the Website;
                    </li>
                    <li>
                        you must have the full legal capacity to conclude agreements under the Applicable Law;
                    </li>
                    <li>
                        comply with additional requirements as regards specific Services set out in these Terms (if applicable).
                    </li>
                </ol>

            </li>
        </ol>
    </>
)

const typesAndScopeOfServiceContent = (
    <>
        <ol>
            <li>
                The types of the Services are as follows:

                <ol className="child-list">
                    <li>
                        Content Access Service;
                    </li>
                    <li>
                        Newsletter Service.
                    </li>
                </ol>

            </li>
            <li>
                The agreement on the use of the Website and Services is concluded as regards:

                <ol className="child-list">
                    <li>
                        the Content Access Service – when the User accesses the Website;
                    </li>
                    <li>
                        the Contact Form Service – when the User (i) enters his or her e-mail address to the contact form; and (ii) accepts these Terms and Privacy Policy by ticking the appropriate checkbox(es); and (iii) clicks on the “Send message” button (or button with equivalent information) and receives infirmation on the Platform’s interface (“Message sent!” or equivalent information).
                    </li>
                </ol>

            </li>
            <li>
                The agreement on the use of the Website and Services is concluded for the duration of the Service(s).
            </li>
            <li>
                INFINITY may terminate the Agreement by, with immediate effect if any of these valid reasons arise:

                <ol className="child-list">
                    <li>
                        the User is in breach of these Terms, in particular the rules of conduct set out in Section 6. GENERAL RULES OF USE OF THE SERVICES;
                    </li>
                    <li>
                        the User grossly violates Applicable Law or the rights of INFINITY or Third Parties in connection with the User's use of the Services, in particular intellectual property rights;
                    </li>
                    <li>    
                        the User carries out activities detrimental to INFINITY or Third Parties, in particular activities that violate or threaten the security of ICT Systems;
                    </li>
                    <li>
                        the User uses the Services in a manner contrary to their purpose or scope as set out in these Terms;
                    </li>
                    <li>
                        the User attempts to gain unauthorised access to the Website;
                    </li>
                    <li>
                        the User overcomes software security features that restrict or protect the use of Content;
                    </li>
                    <li>
                        it is requested by law enforcement or other public authorities.
                    </li>
                </ol>

            </li>
        </ol>
    </>
)

const generalRulesContent = (
    <>
        <ol>
            <li>
                The User is authorized to use the Website and/or the Services exclusively for personal use.
            </li>
            <li>
                The User is obliged to use the Services in accordance with these Terms, the Applicable Law and good market practices, respecting personal rights and intellectual property rights, in particular our rights or rights of the Third Parties. In particular, the User undertakes:

                <ol className="child-list">
                    <li>
                        not to transmit or upload any Prohibited Content;
                    </li>
                    <li>
                        not interfere with or disrupt the operation of the Services or ICT Systems;
                    </li>
                    <li>
                        not impersonate any person or entity, misrepresent information, or otherwise misrepresent your affiliation with a person or entity;
                    </li>
                    <li>
                        not support, assist, or promote any criminal activity or enterprise;
                    </li>
                    <li>
                        not advertise or offer to sell or purchase any goods or services for any purpose not expressly permitted by us.
                    </li>
                </ol>

            </li>
        </ol>
    </>
)

const contentAccessServiceContent = (
    <>
        <ol>
            <li>
                The Content Access Service consists of enabling the User to access the Content.
            </li>
            <li>
                To start using the Content Access Service, the User must visit the Website using the User’s web browser.
            </li>
            <li>
                THE CONTENT OR OTHER INFORMATION PUBLISHED ON THE WEBSITE IS PROVIDED FOR INFORMATION PURPOSES ONLY AND DOES NOT CONSTITUTE AN OFFER, ADVICE OR RECOMMENDATION TO BUY OR SELL ANY ASSETS OR TO ENGAGE IN ANY TRANSACTION, NOR DOES IT CONSTITUTE ADVICE OF ANY OTHER NATURE, SUCH AS TAX OR LEGAL ADVICE. ALL DECISIONS MADE BY THE USER ARE BASED SOLELY ON THE USER’S OWN ASSESSMENT OF THE USER’S FINANCIAL SITUATION AND OBJECTICES. THE USER’S DECISIONS ARE USER’S SOLE RESPONSIBILITY.
            </li>
            <li>
                The use of the Content Access Service is free of charge.
            </li>
        </ol>
    </>
)

const contactFormSerivceContent = (
    <>
        <ol>
            <li>
                The Contact Form Service consists of sending information to INFINITY via the Website’s interface in order to receive a response from INFITY to the User’s email address provided in the contact form.
            </li>
            <li>
                To use the Content Access Service, the User must:

                <ol className="child-list">
                    <li>
                        visit the Website using the User’s web browser;
                    </li>
                    <li>
                        enter his or her e-mail address to the contact form;
                    </li>
                    <li>
                        accept these Terms and Privacy Policy by ticking the appropriate checkbox(es);
                    </li>
                    <li>
                        click on the “Send message” button (or button with equivalent information) and receives confirmation on the Platform’s interface (“Message sent!”) or equivalent information.
                    </li>
                </ol>
            </li>
            <li>
                The User is prohibited from uploading Prohibited Content to INFINITY in connection with use of the Contact Form Service.
            </li>
            <li>
                The User will receive a response from INFINITY to the User’s email address provided in the contact form.
            </li>
            <li className="bold">
                The Contact Form Service is not a newsletter subscription. By using the Contact Form Service, you are not added to our emailing lists. We will not send you any marketing or promotional materials without you prior consent.
            </li>
            <li>
                The use of the Contact Form Service is free of charge.        
            </li>
        </ol>
    </>
)

const intellectualpropertyRightsContent = (
    <>
        <ol>
            <li>
                INFINITY grants to the User a non-exclusive, non-transferable, royalty-free, copyright license, without the right to sub-license, to use the Website in accordance with these Terms.
            </li>
            <li>
                The Content, which includes graphical elements, is protected by copyright, trademark protection rights (or priority rights to register trademarks), and other intellectual property. INFINITY does not assign any copyrights or provide any licenses to use the Content outside of the license set out in this Section 9. INTELLECTUAL PROPERTY RIGHTS. Any use of the Content outside of the scope of the license, may constitute a breach of INFINITY’ intellectual property rights.
            </li>
        </ol>
    </>
)

const thirdPartyService = (
    <>
        <ol>
            <li>
                The Website may contain hyperlinks to external services or websites of Third-Parties. These Terms do not apply to such Third-Party Services or websites unless expressly stated otherwise. If the User uses them, he/she does so at his/her own risk and subject to the relevant terms of services and privacy policy of such Third-Parties. INFINITY recommends that each User reads their terms of service and privacy policies for further information before accessing or using Third-Party Services or websites. INFINITY is not responsible for any Third-Party Services accessed via a hyperlink from the Website, including for their accuracy and/or completeness.
            </li>
        </ol>
    </>
)

const privacyAndDataProtectionContent = (
    <>
        <ol>
            <li>
                INFINITY processes the User’s personal data in accordance with the Applicable Law. Information on processing of the User's personal data and information on cookies can be found in the Privacy Policy.
            </li>
        </ol>
    </>
)

const complaintsContent = (
    <>
        <ol>
            <li>
                INFINITY shall exercise due diligence to ensure that the Services and the Website operate at an appropriate level.
            </li>
            <li>
                The User has the right to report objections and errors in the functioning of the Services and the Website by contacting INFINITY by email at:
            </li>
            <li>
                The User should state in the complaint:

                <ol className="child-list">
                    <li>
                        the contact details required to send a reply to the complaint or claim;
                    </li>
                    <li>
                        a detailed description of the event giving rise to the complaint;
                    </li>
                    <li>
                        demands of the User making the complaint.
                    </li>
                </ol>

            </li>
            <li>
                INFINITY may ask the User to provide additional information to the extent necessary to provide a complete response.
            </li>
            <li>
                Complaints and notifications will be dealt with immediately, no later than within 14 days. Failure to provide or incomplete provision of the information specified in Section 12.3 may make it difficult or impossible to respond to the complaint.
            </li>
            <li>
                A complaint may be submitted anonymously if providing the User's personal data is not necessary for processing the complaint.
            </li>
            <li>
                INFINITY will send the User a response to the complaint to the email address from which the complaint was sent unless the User has indicated that he/she wishes to receive a response to a different email address.
            </li>
        </ol>
    </>
)

const liabilityContent = (
    <>
        <ol>
            <li>
                Subject to the mandatory provisions of the Applicable Law and other provisions of these Terms, our total liability on any basis (i.e. contractual, tort) for damages caused by INFINITY to the User who is not a Consumer in any calendar year of these Terms shall be limited to an amount equal to 100 EUR (liability limitation).
            </li>
            <li>
                Subject to the mandatory provisions of the Applicable Law, INFINITY shall not be liable (liability exclusion):

                <ol className="child-list">
                    <li>
                        for restricting, delaying, or preventing, in whole or in part, the User’s access to the Website or the Services as a result of acts, events, omissions or accidents beyond its reasonable control, including, strikes, failure of public utility services or telecommunications network, including the Internet, war, riot, civil commotion, malicious damage;
                    </li>
                    <li>
                        for restricting, delaying, or preventing, in whole or in part, the User’s access to the Website or the Services due to necessary maintenance, upkeep or development work (including updates) in relation to the Website or the Services;
                    </li>
                    <li>
                        for damage caused by circumstances beyond its direct or indirect control;
                    </li>
                    <li>
                        for Third-Party Services, including their accuracy and completeness;
                    </li>
                    <li>
                        for acts or omissions of the User, in particular for damages caused by the illegal use of any of the Services.
                    </li>
                </ol>

            </li>
        </ol>
    </>
)

const LiabilityContent = (
    <>
        <ol>
            <li>
                INFINITY reserves the right to amend these Terms for important reasons, i.e.:
                
                <ol className="child-list">
                    <li>
                        in the event of a change in legislation, the need to adapt these Terms to the Applicable Law directly affecting these Terms and resulting in the need to modify them to comply with the Applicable Law;
                    </li>
                    <li>
                        the need to adapt these Terms to recommendations, orders, rulings, provisions, interpretations, guidelines, or decisions of authorised public authorities;
                    </li>
                    <li>
                        extending or changing the functionality of the Website, including the introduction of new services provided electronically or changing the existing functionality of the Website;
                    </li>
                    <li>
                        changing the technical conditions for the provision of the Services;
                    </li>
                    <li>
                        the need to rectify any ambiguities, errors or clerical mistakes that may have occurred in these Terms;
                    </li>
                    <li>
                        changing the contact details, names, identification numbers, electronic addresses or links provided in these Terms;
                    </li>
                    <li>
                        to prevent abuse or for security reasons, including to enable the Services to be used in a legally compliant manner;
                    </li>
                    <li>
                        improving the quality of service.
                    </li>
                </ol>

            </li>
            <li>
                If the User is a Consumer, INFINITY will inform the User of the planned change to these Terms by publication on the Website, or by email that the User has provided when using the Services. In such case INFINITY will provide the User who is a Consumer with:

                <ol className="child-list">
                    <li>
                        the content of the planned change to these Terms;
                    </li>
                    <li>
                        the effective date of the change no less than 7 days prior to such change;
                    </li>
                    <li>
                        the consolidated text of the amended Terms.
                    </li>
                </ol>

            </li>
            <li>
                If the User does not agree to the proposed changes of these Terms, the User is entitled to terminate Agreement under these Terms, effective on the day immediately preceding the date of the proposed amendment. The User must inform INFINITY of the User’s decision by sending an email to the following email address. Continued use of the Website or the Services means that the User agrees to be bound by the current version of the Terms.
            </li>
        </ol>
    </>
)

const disputesContent = (
    <>
        <ol>
            <li>
                If any disputes arise in the future concerning these Terms, they will be settled by a court of competent jurisdiction, subject to Sections 15.2 – 15.3 below.
            </li>
            <li>
                If a dispute, claim or any matter arises in connection with these Terms, the User may contact INFINITY in accordance with Section 2. CONTACT.
            </li>
            <li>
                The User is entitled to make use of out-of-court complaint and redress procedures. For this purpose, the User who is a Consumer may, in particular, seek assistance from consumer organizations and municipal or district consumer ombudsmen. The User can use the Online Dispute Resolution (ODR) platform available at: http://ec.europa.eu/consumers/odr/.
            </li>
        </ol>
    </>
)

const applicableLawContent = (
    <>
        <ol>
            <li>
                These Terms will be governed by the law of England subject to Section 16.2 below. In matters not regulated by these Terms, the relevant provisions of the Applicable Law shall apply.
            </li>
            <li>
                For the avoidance of doubt, these Terms shall not limit any rights the Users may have as a Consumer that cannot be excluded or limited under Applicable Law. In the event that User as a Consumer has such rights which cannot be limited, the provisions of the Applicable Law shall apply.
            </li>
        </ol>
    </>
)

const finalProvisionsContent = (
    <>
        <ol>
            <li>
                In matters not regulated by these Terms, the relevant provisions of the Applicable Law shall apply.
            </li>
            <li>
                These Terms comprise the entire contractual relationship between the User and INFINITY and supersede all prior arrangements, agreements, and understandings of any kind between the User and INFINITY.
            </li>
            <li>
                INFINITY reserves the right to transfer the rights and obligations of INFINITY set out in these Terms to INFINITY affiliates or other members of INFINITY group. If the User does not consent to such transfer, the User will be entitled to terminate the Agreement.
            </li>
            <li>
                If any provision of these Terms is declared invalid or ineffective in whole or in part by a court or other competent authority, or if the invalidity or ineffectiveness of any provision of these Terms is based on binding legal provisions, or if any provision of these Terms proves to be unenforceable in whole or in part, the remaining provisions of these Terms shall remain in full force and effect.
            </li>
            <li>
                All warranties, representations, conditions, and any other terms of any kind implied by statute or common law are excluded to the fullest extent permitted by Applicable Law.
            </li>
        </ol>
    </>
)

const definitionsContent = (
    <>
        <ol>
            <li>
                <span>Applicable Law –</span> any and all applicable laws, statutes, ordinances, orders, regulatory requirements, rules and regulations and other similar legal instruments, whether state, EU, federal or local, in force at any time in relation to the User or INFINITY in connection with the use of the Website and/or the Services.
            </li>
            <li>
                <span>Consumer –</span> any natural person making a legal transaction with INFINITY who is not directly related to its professional or business activity.
            </li>
            <li>
                <span>Contact Form Service –</span> the service provided to the User under these Terms, described in particular in Section 8. CONTACT FORM SERVICE.
            </li>
            <li>
                <span>Content –</span> any content, comments, remarks, data, information, text, photos, images, digital or other material displayed by INFINITY or made available by INFINITY by any other means on or through the Website in connection with Services.
            </li>
            <li>
                <span>Content Access Service –</span> the service provided to the User under these Terms, described in particular in Section 7. CONTENT ACCESS SERVICE.
            </li>
            <li>
                <span>ICT Systems -</span> a set of interoperable computing devices and software providing for the processing and storage of data as well as the sending and receiving of data over telecommunications networks, including all computers, communications, electronic, data processing or cloud systems used by INFINITY or the User in relation to the use and/or provision of the Services.
            </li>
            <li>
                <span>INFINITY</span> (“we”, “our”, “us” etc.) – INFINITY ICS LTD. registered in United Kingdom, company number […], address: 20 Wenlock Road, N1 7GU, London, United Kingdom.
            </li>
            <li>
                <span>Prohibited Content –</span> any content, comments, remarks, data, information, text, photos, images, digital or other material made, shared, used or uploaded by the User that (i) infringes any intellectual property or other proprietary rights of INFINITY or any Third-Party; or (ii) contains viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any of the Services; or (iii) constitutes or poses a threat to the privacy or security of any person; or (iv) constitutes unsolicited or unauthorized advertising or commercial activity; or (v) is unlawful, harmful, threatening, abusive, harassing, defamatory, obscene, pornographic, libelous or otherwise objectionable; or (vi) in the sole judgment of INFINITY, is inappropriate or restrict or inhibit others from using the Services, or may expose INFINITY or Users to any damage or liability of any kind.
            </li>
            <li>
                <span>Services –</span> the services provided by INFINITY to the User through the Website, i.e.: the Content Access Service.
            </li>
            <li>
                <span>Terms –</span> these Terms of Service.
            </li>
            <li>
                <span>Third-Party –</span> an entity other than you or INFINITY that provides the Third-Party Services.
            </li>
            <li>
                <span>Third-Party Service –</span> services or solutions provided by a Third-Party that are linked to on the Website.
            </li>
            <li>
                <span>User ("you", "your" etc.) –</span> a natural or legal person using the Website and/or the Services.
            </li>
            <li>
                <span>Website –</span> the website operated by INFINITY through which the Services are provided, available at: https://infinityics.com.
            </li>
        </ol>
    </>
)


const TermsOfServiceData = [
    {
        tosName:"GENERAL PROVISIONS",
        tosContent: generalProvisionsContent,
    },
    {
        tosName:"CONTACT",
        tosContent: contactContent,
    },
    {
        tosName:"TECHNICAL REQUIREMENTS FOR THE USE OF THE WEBSITE AND SERVICES",
        tosContent: technicalRequiremnentsContent,
    },
    {
        tosName:"LEGAL REQUIREMENTS FOR THE USE OF THE WEBSITE AND SERVICES",
        tosContent: legalRequirementsContent,
    },
    {
        tosName:"TYPES AND SCOPE OF THE SERVICES",
        tosContent: typesAndScopeOfServiceContent,
    },
    {
        tosName:"GENERAL RULES OF USE OF THE SERVICES",
        tosContent: generalRulesContent,
    },
    {
        tosName:"CONTENT ACCESS SERVICE",
        tosContent: contentAccessServiceContent,
    },
    {
        tosName:"CONTACT FORM SERVICE",
        tosContent: contactFormSerivceContent,
    },
    {
        tosName:"INTELLECTUAL PROPERTY RIGHTS",
        tosContent: intellectualpropertyRightsContent,
    },
    {
        tosName:"THIRD PARTY SERVICES",
        tosContent: thirdPartyService,
    },
    {
        tosName:"PRIVACY AND PERSONAL DATA PROTECTION",
        tosContent: privacyAndDataProtectionContent,
    },
    {
        tosName:"COMPLAINTS",
        tosContent: complaintsContent,
    },
    {
        tosName:"LIABILITY",
        tosContent: liabilityContent,
    },
    {
        tosName:"LIABILITY",
        tosContent: LiabilityContent,
    },
    {
        tosName:"DISPUTES",
        tosContent: disputesContent,
    },
    {
        tosName:"APPLICABLE LAW",
        tosContent: applicableLawContent,
    },
    {
        tosName:"FINAL PROVISIONS",
        tosContent: finalProvisionsContent,
    },
    {
        tosName:"DEFINITIONS",
        tosContent: definitionsContent,
    }
]
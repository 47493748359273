import { gsap } from 'gsap';
import React, { useState } from 'react';
import { useGSAP } from '@gsap/react';
import { scrollRefresh } from '../gsapConfig';



const Preloader = ({ animationPlay, setLoading }) => {
    const [isFinished, setFinished] = useState(false);
    const [flip, setFlip] = useState(false);
    


    useGSAP(() => {
        if (!animationPlay) {
            
            let mm = gsap.matchMedia();

            mm.add("(min-width: 767px)", () => {
                let tl2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".preloader",
                        start: "top top",
                        end: '40%', // Adjust the end value as needed to cover all animations
                        snap: 0.1,
                        scrub: 1,
                        onEnter: () => {
                            document.querySelector(".scroll-downs").classList.add("removed");  
                        },
                       
                    }
                });

                tl2.fromTo("#circle",{ opacity:1}, {  opacity: 0, duration: 1}); // Align with the start of the timeline
                tl2.fromTo(".preloader .content",{ opacity:1}, { opacity: 0, duration: 0.75 }, 0); // Align with the start of the timeline
                tl2.to(".desktopBackground", { opacity: 1, delay: 0.75, duration: 0.5 }, 0); // Align with the start of the timeline
                tl2.to('.preloader', { marginBottom: -700, duration: 0.75, onComplete: () => {
                    scrollRefresh();
                }}, 0); 
            });

            mm.add("(max-width: 766px)", () => {
                let tl2 = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".preloader",
                        start: "top top",
                        end: '100%', // Adjust the end value as needed to cover all animations
                        snap:0.1,
                        scrub: 1,
                        onEnter: () => {
                            document.querySelector(".scroll-downs").classList.add("removed");  
                        },
                    }
                });

                tl2.to("#circle", { opacity: 0, duration: 1}); // Align with the start of the timeline
                tl2.to(".preloader .content", {  opacity: 0, duration: 0.75 }, 0); // Align with the start of the timeline
                tl2.to(".mobileBackground", { opacity: 1, delay: 0.75, duration: 0.5 }, 0); // Align with the start of the timeline
            });

            return;

        }
        
        let mm2 = gsap.matchMedia();

        mm2.add("(min-width: 767px)", () => {
            let tl = gsap.timeline();
            const dur = false;
        
            tl.to("#circle", { scale: "1.05", y: "100", boxShadow: "0px -150px 200px -120px #A01328", duration: dur || 2 });
            tl.to("#heading", { scale: "1.05", opacity: "1", duration: dur || 1 });
            tl.to("#slogan", { y: "0", opacity: "1", delay: 0.25, duration: dur || 1, onStart: function () {
                    setFlip(true);
            }});
            tl.to(".desktopBackground", { opacity: "0.5", duration: dur || 2, onComplete: function () {
                    setLoading(false);
                    setFinished(true);
            }});
        
        });
        
        mm2.add("(max-width: 766px)", () => {
            let tl = gsap.timeline();
            const dur = false;
        
            // Modify the animations or keep them the same depending on your needs
            tl.fromTo("#circle",{scale:1.05, boxShadow:"0px 0px 80px -60px rgb(241 0 36)" }, { scale: "1.05", y: "50", boxShadow: "0px -50px 80px -60px rgb(241 0 36)", duration: dur || 2 });
            tl.to("#heading", { scale: "1.05", opacity: "1", duration: dur || 1 });
            tl.to("#slogan", { y: "0", opacity: "1", delay: 0.25, duration: dur || 1, onStart: function () {
                    setFlip(true);
            }});
            tl.to(".mobileBackround", { opacity: "0.5", duration: dur || 2, onComplete: function () {
                    setLoading(false);
                    setFinished(true);
            }});
        
        });
        

    }, { dependencies: [animationPlay] });

    return (
        <div className={isFinished ? "preloader finished" : "preloader" } id="preloader">
            <div className="circle" id="circle" ></div>
            <div className="inner">
                <div className="content" id="content">
                    <div className={flip ? "heading flip-the-ns" : "heading"} id="heading">
                        <svg width="587" height="105" viewBox="0 0 587 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1_529)">
                                <path id="i" d="M0 0H21.3818V104.144H0V0Z" fill="white" />
                                <path id="n" className="n" d="M42.8004 104.144H63.8873V45.6391L128.844 104.218V0.0737304H107.757V58.7262L42.8004 0V104.144Z" fill="white" />
                                <path id="f" d="M149.562 0.0737305H213.265V19.5017H170.943V39.3352H209.504V58.3576H170.943V104.181H149.562V0.0737305Z" fill="white" />
                                <path id="i" d="M230.517 0.0737305H251.899V104.181H230.517V0.0737305Z" fill="white" />
                                <path id="n" className="n" d="M273.319 104.144H294.406V45.6391L359.362 104.218V0.0737304H338.275V58.7262L273.319 0V104.144Z" fill="white" />
                                <path id="i" d="M380.633 0.0737305H402.015V104.181H380.633V0.0737305Z" fill="white" />
                                <path id="t" d="M442.013 19.5017H415.802V0.0737305H489.201V19.5017H463.395V104.181H442.013V19.5017Z" fill="white" />
                                <path id="y" d="M529.936 60.717L494.398 0.0737305H517.587L540.627 40.3305L563.631 0.0737305H586.819L551.281 60.717V104.181H529.9V60.717H529.936Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1_529">
                                    <rect width="586.82" height="104.218" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                    <div className="slogan" id="slogan">Infinite Potential,<br />Driven by Us</div>
                    <div className={isFinished ? "scroll-downs ready" : "scroll-downs" }>
                        <div class="mousey">
                            <div class="scroller"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Preloader;

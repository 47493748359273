import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/all';
import Lenis from "lenis";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function scrollToSection(element){
    const elementRef = document.querySelector(element);
    const height = elementRef.getBoundingClientRect().bottom - elementRef.getBoundingClientRect().top
    let offsetTop = elementRef.getBoundingClientRect().top + window.scrollY ;
    if(element === '#services'){
        if(offsetTop > window.scrollY){
            offsetTop -= 600; // to accomodate for the section scrolling Up
        }
    } else if (element === '#our-team'){
        if(offsetTop > window.scrollY){
            offsetTop = elementRef.getBoundingClientRect().top + window.scrollY + height;        
        }
    }

    gsap.to(window, {
        // eslint-disable-next-line no-restricted-globals
        scrollTo: { y: offsetTop , autoKill: false, ease: "power3.inOut" },
        duration: 0.5
    });
}

const scrollHandler = {
    enabled: true,
    events: "scroll,wheel,touchmove,pointermove".split(","),
    prevent: e => e.preventDefault(),
    disable() {
      if (scrollHandler.enabled) {
        scrollHandler.enabled = false;
        window.addEventListener("scroll", gsap.ticker.tick, {passive: true});
        scrollHandler.events.forEach((e, i) => (i ? document : window).addEventListener(e, scrollHandler.prevent, {passive: false}));
      }
    },
    enable() {
      if (!scrollHandler.enabled) {
        scrollHandler.enabled = true;
        window.removeEventListener("scroll", gsap.ticker.tick);
        scrollHandler.events.forEach((e, i) => (i ? document : window).removeEventListener(e, scrollHandler.prevent));
      }
    }
};

const lenis = new Lenis({
  duration: 3,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  smoothWheel: true,
});

const smoothScroll = (isActive) =>{   

    if (isActive) {

      function raf(time) {
        lenis.raf(time);
        ScrollTrigger.update();
        requestAnimationFrame(raf);
      }
      requestAnimationFrame(raf);
    
    } else {
    
      lenis.destroy();
    
    }
} 

const stopScroll = () => {
  lenis.stop()
  console.log("scroll stop")
}
const startScroll = () => {
  lenis.start()
  console.log("scroll start")
}

function scrollRefresh () {
    ScrollTrigger.refresh()
}

export { gsap, ScrollTrigger , scrollToSection, scrollRefresh, scrollHandler, smoothScroll, stopScroll, startScroll};